import { isEmpty } from 'lodash';
import { utils, writeFile } from 'xlsx';

export const generateCSV = (
  data: any,
  filename: string,
  keyfigures: any,
  cb?: Function
  // sheet_name?: string
) => {
  /* create a new workbook */
  const wb = utils.book_new();

  /* create pages */
  /**
   * data = { page1_name: page1_data, page2_name: page2_data }
   */

  if (!isEmpty(keyfigures)) {
    const keyfigures_ws = utils.aoa_to_sheet([]);
    let idx = 1;
    for (const key in keyfigures) {
      utils.sheet_add_aoa(keyfigures_ws, [[key]], { origin: `A${idx}` });
      utils.sheet_add_aoa(keyfigures_ws, keyfigures[key], {
        origin: `A${idx + 1}`,
      });
      idx = idx + 1 + keyfigures[key].length + 1; // +1: space for title, +1: space left between key figures
    }
    utils.book_append_sheet(wb, keyfigures_ws, 'Filtres');
  }

  for (const page in data) {
    let pattern = /[\\/?*:[]/g;
    const str = page.replace(pattern, '');
    const ws = utils.aoa_to_sheet(data[page]);
    utils.book_append_sheet(wb, ws, str.substring(0, 31));
  }

  // const treated_fname = filename.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "").replace(" ", "-")
  /* generate XLSX file and send to client */
  writeFile(wb, `${filename}.xlsx`);

  cb && cb();
};
